import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Cta from '../ContentfulCta/Cta';
import CtaWrapper from '../ContentfulCta/CtaWrapper';
import ContentfulSpotlightList from '../ContentfulSpotlightList/ContentfulSpotlightList';
import * as styles from './HomepageProjectSpotlight.module.scss';

const HomepageProjectSpotlight = ({ title }) => {
  const data = useStaticQuery(query);
  return (
    <div className={styles.projectSpotlightWrapper}>
      <ContentfulSpotlightList
        title={title}
        projects={data?.allContentfulProject?.nodes || []}
      />
      <Link to="/work">
        <CtaWrapper CtaPositionCondition>
          <Cta isInternalLink className={styles.ctaPosition}>
            See more work
          </Cta>
        </CtaWrapper>
      </Link>
    </div>
  );
};

export default HomepageProjectSpotlight;

export const query = graphql`
  query HomepageProjectSpotlightQuery {
    allContentfulProject(
      filter: { priorityProject: { eq: true }, title: { ne: "__DUMMY_ITEM__" } }
      limit: 3
      sort: { fields: publishDate, order: DESC }
    ) {
      nodes {
        id
        title
        heroImage {
          gatsbyImageData
          file {
            url
          }
          title
        }
        client {
          clientName
          clientAccent
          clientHeaderBackground
          clientHeaderForeground
        }
        missionStatement {
          missionStatement
        }
      }
    }
  }
`;
