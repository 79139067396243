import React, { useEffect, useState } from 'react';
import LottieAnimation from '../ContentfulLottieAnimation/LottieAnimation';
import * as styles from './RocketAnimation.module.scss';

const urlDesktop = '/assets/animations/homepage-header-desktop.json';
const urlMobile = '/assets/animations/homepage-header-mobile.json';

const RocketAnimation = ({ isDesktop }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const scrollHandler = (event) => {
      setProgress(window.scrollY / window.innerHeight);
    };
    // Needs update on every scroll, don't throttle
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <div className={styles.animationWrapper}>
      {isDesktop ? (
        <LottieAnimation
          progress={1.2 * progress}
          url={urlDesktop}
          maxSpeed={30}
        />
      ) : (
        <div className={styles.animationMobile}>
          <LottieAnimation
            progress={1.2 * progress}
            url={urlMobile}
            maxSpeed={30}
          />
        </div>
      )}
    </div>
  );
};

export default RocketAnimation;
