import React, { useContext } from 'react';
import logo from '../../images/logo.svg';
// import rocketTrail from '../../images/rocket-trail.svg';
import * as styles from './HomeHeroAnimation.module.scss';

import {
  ScrollContainer,
  ScrollPage,
  batch,
  Fade,
  //MoveIn,
  //MoveOut,
  Sticky,
  ScrollContainerContext,
  ScrollPageContext,
} from 'react-scroll-motion';
import { Link } from 'gatsby';
import RocketAnimation from '../RocketAnimation/RocketAnimation';
import BigCircleCta from '../BigCircleCta/BigCircleCta';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import ContentfulLottieAnimation from '../ContentfulLottieAnimation/ContentfulLottieAnimation';
import useMediaQuery from '../../hooks/useMediaQuery';

const Text = ({ children }) => <p>{children}</p>;
const options = {
  renderNode: {
    paragraph: (node, children) => <Text>{children}</Text>,
  },
};

const Animator = ({ children, animation = {} }) => {
  const { currentPage, currentProgress } = useContext(ScrollContainerContext);

  const { page } = useContext(ScrollPageContext);

  const filterStyle = (style = {}, ...params) => {
    const filteredStyle = { ...style };
    for (const key in filteredStyle)
      if (typeof filteredStyle[key] === 'function')
        filteredStyle[key] = filteredStyle[key](...params);
    return filteredStyle;
  };

  const calculatedStyle =
    currentPage === page // for current (out)
      ? { ...filterStyle(animation?.out?.style, currentProgress) }
      : currentPage === page - 1 // for next (in)
      ? { ...filterStyle(animation?.in?.style, currentProgress) }
      : {};

  return <div style={calculatedStyle}>{children}</div>;
};

const AboutUSSection = ({
  positioningStatementLink,
  positioningStatementLinkText,
  positioningStatement,
}) => (
  <div className={styles.scrollAnimationContentBgr}>
    <ScrollPage page={1}>
      <div className={styles.scrollAnimationContent}>
        <div className={styles.scrollAnimationInfo}>
          <Animator>{renderRichText(positioningStatement, options)}</Animator>
        </div>
        <Link
          to={`/${positioningStatementLink}`}
          className={styles.scrollAnimationCta}>
          <Animator>
            <div className={styles.aboutUsCtaWrapper}>
              <BigCircleCta bgrColor="#a888fd" textColor="#1a1a1a">
                {positioningStatementLinkText}
              </BigCircleCta>
            </div>
          </Animator>
        </Link>
      </div>
    </ScrollPage>
  </div>
);

const BreaktroughAnimation = ({ isDesktop }) => {
  return isDesktop ? (
    <div className={styles.breaktroughTextDesktop}>
      <ContentfulLottieAnimation
        lottieFile={{
          file: { url: '/assets/animations/brave-desktop.json' },
        }}
        offset={0.3}
        scrollProgressLever={1.2}
      />
    </div>
  ) : (
    <div className={styles.breaktroughTextMobile}>
      <ContentfulLottieAnimation
        lottieFile={{
          file: { url: '/assets/animations/brave-mobile.json' },
        }}
        offset={0.2}
      />
    </div>
  );
};

const HomeHeroAnimation = ({
  positioningStatementLink,
  positioningStatementLinkText,
  positioningStatement,
}) => {
  const isDesktop = useMediaQuery('(min-width: 600px)');

  return (
    <div style={{ position: 'relative' }}>
      <RocketAnimation isDesktop={isDesktop} />
      <ScrollContainer>
        <ScrollPage page={0}>
          <Animator animation={batch(Fade(), Sticky())}>
            <div className={styles.scrollAnimation}>
              <p className={styles.scrollAnimationTitle}>We are</p>
              <img
                className={styles.scrollAnimationLogo}
                src={logo}
                alt="Bernadette logo"
              />
              <p className={styles.scrollAnimationDescription}>
                A Digital Experience Agency
              </p>
            </div>
          </Animator>
        </ScrollPage>
      </ScrollContainer>
      <AboutUSSection
        positioningStatementLink={positioningStatementLink}
        positioningStatementLinkText={positioningStatementLinkText}
        positioningStatement={positioningStatement}
      />
      <BreaktroughAnimation isDesktop={isDesktop} />
    </div>
  );
};

export default HomeHeroAnimation;
