import { Content } from '@vccp/bernadette-components';
import { graphql } from 'gatsby';
import React from 'react';
import HomeClientGrid from '../components/HomeClientGrid/HomeClientGrid';
import HomeHeroAnimation from '../components/HomeHeroAnimation/HomeHeroAnimation';
import HomepageProjectSpotlight from '../components/HomepageProjectSpotlight/HomepageProjectSpotlight';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo/Seo';
import ContactUs from '../components/ContactUs/ContactUs';
import PageTransition from 'gatsby-plugin-page-transitions';
import * as styles from './index.module.scss';

const IndexPage = ({ data, location }) => {
  const pageData = data.contentfulHome;
  return (
    <>
      <Seo
        contentTitle={pageData?.title}
        pathname={location?.pathname}
        seo={pageData?.seo}
      />
      <PageTransition>
        <Layout
          {...(pageData?.pageThemeColour && {
            style: { '--theme-accent-color': `#${pageData?.pageThemeColour}` },
          })}>
          <div style={{ position: 'relative' }}>
            <h1 className={styles.title}>Bernadette</h1>
            {typeof window !== 'undefined' && (
              <HomeHeroAnimation
                positioningStatementLinkText={
                  pageData.positioningStatementLinkText
                }
                positioningStatementLink={
                  pageData.positioningStatementLink?.slug
                }
                positioningStatement={pageData.positioningStatement}
              />
            )}
          </div>
          <Content>
            <HomepageProjectSpotlight title="OUR WORK" />
            <HomeClientGrid />
            <ContactUs />
          </Content>
        </Layout>
      </PageTransition>
    </>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query HomePageQuery {
    contentfulHome {
      title
      seo {
        canonicalUrl
        description
        seoTitle
        image {
          file {
            url
          }
          title
          width
          height
        }
      }
      positioningStatement {
        raw
      }
      positioningStatementLinkText
      positioningStatementLink {
        ... on ContentfulContact {
          slug
        }
        ... on ContentfulPages {
          slug
        }
      }
    }
  }
`;
