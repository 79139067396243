import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ContentfulGetInTouch from '../ContentfulGetInTouch/ContentfulGetInTouch';

const ContactUs = () => {
  const data = useStaticQuery(query);
  const { title, description, link, navigationLinkString } =
    data?.contentfulComponentGetInTouch;

  return (
    <ContentfulGetInTouch
      title={title}
      description={description}
      link={link}
      navigationLinkString={navigationLinkString}
    />
  );
};

export default ContactUs;

export const query = graphql`
  query ContactUsQuery {
    contentfulComponentGetInTouch {
      description {
        description
      }
      link {
        slug
        id
      }
      title
      navigationLinkString
    }
  }
`;
