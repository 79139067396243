// extracted by mini-css-extract-plugin
export var aboutUsCtaWrapper = "HomeHeroAnimation-module--about-us-cta-wrapper--etXk7";
export var breaktroughTextDesktop = "HomeHeroAnimation-module--breaktrough-text-desktop--Wp0eE";
export var breaktroughTextMobile = "HomeHeroAnimation-module--breaktrough-text-mobile--Fz6Fg";
export var container = "HomeHeroAnimation-module--container--BnrAs";
export var scrollAnimation = "HomeHeroAnimation-module--scroll-animation--ohBim";
export var scrollAnimationButton = "HomeHeroAnimation-module--scroll-animation-button--WEUAZ";
export var scrollAnimationContent = "HomeHeroAnimation-module--scroll-animation-content--OdaGf";
export var scrollAnimationContentBgr = "HomeHeroAnimation-module--scroll-animation-content-bgr--jeaee";
export var scrollAnimationCta = "HomeHeroAnimation-module--scroll-animation-cta--9CR5o";
export var scrollAnimationDescription = "HomeHeroAnimation-module--scroll-animation-description--j8SfG";
export var scrollAnimationInfo = "HomeHeroAnimation-module--scroll-animation-info--5sOrk";
export var scrollAnimationLogo = "HomeHeroAnimation-module--scroll-animation-logo--I5tDN";
export var scrollAnimationText = "HomeHeroAnimation-module--scroll-animation-text--X15j+";
export var scrollAnimationTitle = "HomeHeroAnimation-module--scroll-animation-title--h+RTt";
export var scrollAnimationWrapper = "HomeHeroAnimation-module--scroll-animation-wrapper--A1pkz";