import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import ContentfulClientGrid from '../ContentfulClientGrid/ContentfulClientGrid';
import * as styles from './HomeClientGrid.module.scss';
const HomeClientGrid = () => {
  const data = useStaticQuery(query);
  const { clients } = data?.allContentfulComponentClientGrid.nodes[0];
  return (
    <div className={styles.clientGridWrapper}>
      <ContentfulClientGrid
        title="We make amazing work for amazing brands"
        clients={clients}
        link=""
      />
    </div>
  );
};

export default HomeClientGrid;

export const query = graphql`
  query HomeClientGridQuery {
    allContentfulComponentClientGrid(
      filter: { title: { ne: "__DUMMY_ITEM__" } }
    ) {
      nodes {
        title
        clients {
          id
          clientLogoThumbnail {
            gatsbyImageData
            file {
              url
            }
            title
          }
        }
      }
    }
  }
`;
